<template>
  <div v-if="isShownMainDialog" class="feasibility-project-main main-body">
    <div class="messenger-wrapper">
      <h2 class="grey">Forward for TSS</h2>

      <div style="min-width: 60px">
        <h4 class="grey">You will send a message to these groups:</h4>
        <div>
          <div v-if="chosenTss && chosenTss.length" class="ps-list">
            <template v-for="site of chosenTss" :key="site.id + 'svisibility'">
              <div class="small-box">
                <p class="small-box-text">TSS: {{ site.title }}</p>
                <div
                  class="icon-checked-wrapper"
                  @click="selectProcurementSite(site)"
                >
                  <IconClose icon-color="#eaf5f7" width="8" height="8" />
                </div>
              </div>
            </template>
          </div>
          <p v-else class="grey">... not chosen</p>
        </div>
      </div>

      <div class="messenger-window">
        <div>
          <textarea
            v-model="message"
            class="messenger-textarea"
            rows="5"
          ></textarea>
        </div>

        <div style="min-height: 21px">
          <p v-if="isSending" class="green center">
            Your message has been sent
          </p>
        </div>

        <div>
          <BaseButton
            className="info big"
            text="Send Message"
            :disabled="!message || !chosenTss.length"
            @click="sendMessageToChosenTss"
          />
        </div>
      </div>
    </div>
  </div>

  <div v-else class="messenger-tss">
    <ChatWithTss
      :tss="selectedTss"
      :should-close-connection="isShownMainDialog"
      @close="showMainChat"
    />
  </div>

  <div class="messenger-sidebar sidebar">
    <div class="messenger-checkbox-wrp">
      <BaseCheckbox :checked="false" @set-checkbox-value="selectAllTss" />
      <p class="green" style="padding-left: 10px"><b> Select All TSS</b></p>
    </div>

    <div v-if="sites && sites.length" class="messenger-list">
      <div v-for="site in sites" :key="site.id">
        <div
          class="messenger-tss-card-wrp"
          :class="{
            active: chosenTss.findIndex((tss) => tss === site.id) >= 0,
          }"
        >
          <div class="card-checkbox-wrp">
            <input
              class="my-checkbox"
              type="checkbox"
              :value="site.id"
              :checked="chosenTss.map(({ id }) => id).includes(site.id)"
              @click="selectProcurementSite(site)"
            />
          </div>

          <div class="messenger-tss-card" @click="showChatById(site)">
            <div class="img-wrapper">
              <img :src="site.avatar" alt="" />
            </div>

            <div class="card-text">
              <p class="card-time">{{ getLastMsgTime(site.updated_at) }}</p>
              <div
                v-if="site.updated_at && !site.seen"
                class="card-status"
              ></div>
              <h4>{{ site.title }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { useStore } from "vuex";
import ChatWithTss from "@/components/chat/ChatWithTss.vue";
import BaseCheckbox from "@/modules/components/base/BaseCheckbox.vue";
import BaseButton from "@/modules/components/base/BaseButton.vue";
import IconClose from "@/modules/components/icons/IconClose";

export default {
  components: { ChatWithTss, BaseCheckbox, BaseButton, IconClose },
  setup() {
    const store = useStore();
    const sites = computed(() => store.getters["globalMessenger/sites"]);
    const dialogWithTss = computed(
      () => store.getters["globalMessenger/dialog"]
    );
    const admin = computed(() => store.getters["auth/user"]);

    const isShownMainDialog = ref(true);
    const chosenTss = ref([]);
    const message = ref("");
    const isSending = ref(false);
    const selectedTss = ref(null);

    // store.dispatch("site/loadSites");
    store
      .dispatch("globalMessenger/showTSS")
      .then((res) => setConnections(res));

    const sitesConnections = ref([]);
    const setConnections = (sites) => {
      if (sites.length) {
        sites.forEach((site) => {
          sitesConnections.value.push({
            id: site.id,
            connection: new WebSocket(
              "wss://admin.cureline-crm.cureline.com/ap/" +
                localStorage.getItem("authToken") +
                "/" +
                site.id
            ),
          });
        });
      }
    };

    const isChosen = (tssId) => {
      if (chosenTss.value && chosenTss.value.length) {
        return chosenTss.value.map(({ id }) => id).includes(tssId);
      } else {
        return false;
      }
    };

    const showChatById = (site) => {
      selectedTss.value = site;
      store.dispatch("globalMessenger/showAdminChat", site.id);
      isShownMainDialog.value = false;
      site.seen = 1;
    };

    const selectAllTss = () => {
      chosenTss.value =
        chosenTss.value.length === sites.value.length
          ? []
          : sites.value.slice();
    };

    const selectProcurementSite = (site) => {
      if (isChosen(site.id)) {
        chosenTss.value.splice(
          chosenTss.value.map(({ id }) => id).indexOf(site.id),
          1
        );
      } else {
        if (!chosenTss.value) {
          chosenTss.value = [];
        }
        chosenTss.value.push(site);
      }
    };

    const sendMessageToChosenTss = async () => {
      if (message.value) {
        let data = {
          content: message.value,
          procurement_sites: chosenTss.value.map((tss) => tss.id),
        };

        let msg = {
          content: message.value,
          admin_user_id: admin.value.id,

          admin_user: {
            id: admin.value.id,
            avatar: admin.value.avatar,
            name: admin.value.name,
          },
          created_at: new Date().toISOString(),
        };

        for (let i = 0; i < chosenTss.value.length; i++) {
          let con = sitesConnections.value.find((site) => {
            if (site.id === chosenTss.value[i].id) {
              return site.connection;
            }
          });
          con.connection.send(JSON.stringify(msg));
        }

        await store.dispatch("globalMessenger/sendMessage", data);
        message.value = "";
      }
    };

    const showMainChat = () => {
      isShownMainDialog.value = true;
      //   store.commit("globalMessenger/SET_DIALOG_WITH_PS", null);
      //   client.close();
    };

    const getLastMsgTime = (date) => {
      if (date) {
        const formatDate = new Date(date);
        let hours = formatDate.getHours();
        let minutes = formatDate.getMinutes();
        let month = formatDate.getMonth();
        month++;
        let day = formatDate.getDate();

        if (hours < 10) {
          hours = "0" + hours;
        }
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (month < 10) {
          month = "0" + month;
        }
        if (day < 10) {
          day = "0" + day;
        }
        return `${day}.${month} ${hours}:${minutes}`;
      } else {
        return "";
      }
    };

    return {
      sites,
      dialogWithTss,
      admin,
      isShownMainDialog,
      chosenTss,
      message,
      isSending,
      isChosen,
      showChatById,
      selectAllTss,
      selectProcurementSite,
      sendMessageToChosenTss,
      showMainChat,
      selectedTss,
      getLastMsgTime,
    };
  },
};
</script>

<style lang="scss" scoped>
.messenger {
  &-tss {
    flex: 1;
    margin-right: 16px;
    border-radius: 8px;
    background-color: var(--col-bg-secondary);
  }

  &-wrapper {
    max-width: 90%;
    margin: 0 auto;
  }

  &-sidebar {
    flex: none;
    width: 340px;
    margin-bottom: 0;
    padding: 15px 10px;
    border-radius: 8px;
    border: none;
    border-bottom: 10px solid var(--col-info);
    background-color: var(--col-bg-secondary);
  }

  &-list {
    max-height: calc(100vh - 250px);
    overflow: auto;
  }

  &-textarea {
    width: 100%;
    margin: 10px 0;
    padding: 15px 20px;
    border: 1px solid var(--col-border);
    border-radius: 8px;
  }

  &-tss-card {
    &-wrp {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      position: relative;
    }

    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 3px 0;
    border: 1px solid transparent;
    border-left: 5px solid var(--col-info);
    border-radius: 5px;
    box-shadow: 1px 2px 3px #00000014;
    cursor: pointer;

    &:hover {
      background-color: var(--col-info-bg);
    }

    &.active {
      border-right: 1px solid var(--col-info);
      border-top: 1px solid var(--col-info);
      border-bottom: 1px solid var(--col-info);
    }

    .img-wrapper {
      flex-basis: 50px;
      display: flex;
      justify-content: center;
      align-items: stretch;
      min-width: 50px;
      min-height: 50px;
      margin: 5px 5px 5px 10px;
      border-radius: 4px;
      border: none;
      overflow: hidden;
    }
  }

  &-checkbox-wrp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 7px;
  }
}
.card {
  &-checkbox-wrp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 20px;
    margin-right: 10px;
  }

  &-status {
    position: absolute;
    top: 7px;
    right: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--col-info);
  }

  &-time {
    position: absolute;
    top: 7px;
    right: 30px;
    font-size: 10px;
    line-height: 1;
    text-align: right;
    color: #c4c4c4;
  }

  &-text {
    width: 190px;
    padding-left: 10px;
    overflow: hidden;

    p {
      line-height: 1;
    }
  }
}
.my-checkbox[type="checkbox"] {
  position: relative;
  width: 0;
  height: 0;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: -12px;
    z-index: 10;
    display: block;
    width: 17px;
    height: 17px;
    border-radius: 2px;
    border: 1px solid var(--col-info);
  }

  &:checked:before {
    content: "";
    position: absolute;
    left: 6px;
    top: -16px;
    z-index: 20;
    display: block;
    width: 10px;
    height: 16px;
    border-bottom: 2px solid var(--col-info);
    border-right: 2px solid var(--col-info);
    transform: rotate(45deg);
  }
}

.ps-list {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.small-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex: 1;
  margin: 5px;
  border-radius: 4px;
  border: none;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  font-family: "OpenSans-Regular", sans-serif;
  color: var(--col-contrast-text);
  background-color: var(--col-info);
  transition: 0.17s all;
  white-space: nowrap;

  &-text {
    flex: 1;
  }

  .icon-checked-wrapper {
    padding: 0 4px;
    margin-left: 10px;
    cursor: pointer;
  }
}
</style>
